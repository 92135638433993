'use client'

import { XMarkIcon } from '@heroicons/react/24/outline'
import { ToastContainer } from 'react-toastify'

export default function Toaster() {
    return (
        <ToastContainer
            position="bottom-right"
            theme="light"
            autoClose={5000}
            limit={3}
            icon={XMarkIcon}
            newestOnTop
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            hideProgressBar
        />
    )
}
