'use client'

import { createContext, useEffect, useState } from 'react'
import useLeaveConfirmation from './useLeaveConfirmation'

interface LeavePageGuardRegistration {
    name: string
    save: () => Promise<any>
    discard: () => void
}

export type SaveGuardContext = {
    registerLeavePageGuard: (config: LeavePageGuardRegistration) => void
    updateDirtyState: (name: string, isDirty: boolean) => void
}

export const LeavePageGuardContext = createContext<SaveGuardContext>({} as SaveGuardContext)

interface LeavePageGuardProps {
    children: React.ReactNode | React.ReactNode[]
}

export default function LeavePageGuard({ children }: LeavePageGuardProps): JSX.Element {
    const [preventRouteChange, setPreventRouteChange] = useState(false)
    const [dirtyStates, setDirtyStates] = useState<{ [key: string]: boolean }>({})
    const [saveCallbacks, setSaveCallbacks] = useState<{ [key: string]: () => Promise<any> }>({})
    const [discardCallbacks, setDiscardCallbacks] = useState<{ [key: string]: () => any }>({})

    const { confirmationDialog } = useLeaveConfirmation(
        preventRouteChange,
        Object.entries(dirtyStates)
            .filter(([_, isDirty]) => isDirty)
            .map(([name, _]) => name),
        saveChanges,
        discardChanges
    )

    useEffect(() => {
        const anyStateIsDirty = Object.values(dirtyStates).some(x => x)
        setPreventRouteChange(anyStateIsDirty)
    }, [dirtyStates])

    function registerLeavePageGuard(config: LeavePageGuardRegistration) {
        setSaveCallbacks(callbacks => ({ ...callbacks, [config.name]: config.save }))
        setDiscardCallbacks(callbacks => ({ ...callbacks, [config.name]: config.discard }))
    }

    function updateDirtyState(name: string, isDirty: boolean) {
        setDirtyStates(states => ({ ...states, [name]: isDirty }))
    }

    async function saveChanges() {
        for (const [name, isDirty] of Object.entries(dirtyStates)) {
            if (isDirty) {
                await saveCallbacks[name]()
                setDirtyStates(states => ({ ...states, [name]: false }))
            }
        }
    }

    function discardChanges() {
        for (const [name, isDirty] of Object.entries(dirtyStates)) {
            if (isDirty) {
                discardCallbacks[name]()
                setDirtyStates(states => ({ ...states, [name]: false }))
            }
        }
    }

    return (
        <LeavePageGuardContext.Provider value={{ registerLeavePageGuard, updateDirtyState }}>
            {children}
            {confirmationDialog}
        </LeavePageGuardContext.Provider>
    )
}
