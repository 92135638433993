import { ComponentSpinner } from '@tebuto/layout/Spinner'
import TebutoModal from '@tebuto/modals/Modal'
import MediumText from '@tebuto/typography/MediumText'
import { useCallback, useEffect, useRef, useState } from 'react'
import useBeforeUnload from './useBeforeUnload'
import useRouteChangeEvents from './useRouteChangeEvents'

export default function useLeaveConfirmation(shouldPreventRouteChange: boolean, unsavedSections: string[], saveHandler: () => Promise<void>, discardHandler: () => void) {
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
    const [isRedirecting, setIsRedirecting] = useState(false)
    const shouldPreventRouteChangeRef = useRef(shouldPreventRouteChange)

    useEffect(() => {
        shouldPreventRouteChangeRef.current = shouldPreventRouteChange
    }, [shouldPreventRouteChange])

    useBeforeUnload(shouldPreventRouteChange)

    const onBeforeRouteChange = useCallback(() => {
        if (shouldPreventRouteChange) {
            setShowConfirmationDialog(true)
            return false
        }
        return true
    }, [shouldPreventRouteChange])

    const onRouteChangeComplete = useCallback(() => {
        setShowConfirmationDialog(false)
        setIsRedirecting(false)
    }, [showConfirmationDialog])

    const { allowRouteChange } = useRouteChangeEvents({ onBeforeRouteChange, onRouteChangeComplete })

    function allowRouterChangeWhenReady() {
        setTimeout(() => {
            if (shouldPreventRouteChangeRef.current) {
                allowRouterChangeWhenReady()
            } else {
                allowRouteChange()
            }
        }, 100)
    }

    async function handleSave() {
        await saveHandler()
        setIsRedirecting(true)
        allowRouterChangeWhenReady()
    }

    function handleDiscard() {
        discardHandler()
        setIsRedirecting(true)
        allowRouterChangeWhenReady()
    }

    function handleModalClose() {
        setShowConfirmationDialog(false)
    }

    return {
        confirmationDialog: (
            <TebutoModal
                title="Änderungen speichern"
                isOpen={showConfirmationDialog}
                onSubmit={handleSave}
                closeHandler={handleDiscard}
                onModalClose={handleModalClose}
                cancelButtonText="Verwerfen"
                className="max-w-[500px]"
                verticalPadding={false}
            >
                {isRedirecting ? <Redirecting /> : <SaveDecision unsavedSections={unsavedSections} />}
            </TebutoModal>
        )
    }
}

function SaveDecision({ unsavedSections }: { unsavedSections: string[] }) {
    return (
        <div className="grid gap-4 py-6">
            <MediumText>Es gibt ungespeicherte Änderungen in diesen Sektionen:</MediumText>
            <ul className="list-disc pl-6">
                {unsavedSections.map(section => (
                    <li key={section}>
                        <MediumText className="font-semibold">{section}</MediumText>
                    </li>
                ))}
            </ul>
        </div>
    )
}

function Redirecting() {
    return (
        <div className="py-10">
            <ComponentSpinner />
        </div>
    )
}
