'use client'

import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { classNames } from '@tebuto/functions'
import COMPONENT_TEXTS from '@tebuto/translations'
import LargeText from '@tebuto/typography/LargeText'
import SmallText from '@tebuto/typography/SmallText'
import { Form, Formik, FormikHelpers, FormikValues, useFormikContext } from 'formik'
import { ReactNode } from 'react'
import FormButtons from '../forms/Buttons'

export interface ITebutoModal<T extends FormikValues> {
    isOpen: boolean
    title: string
    subTitle?: string
    initialValues?: T
    description?: string
    validationSchema?: any
    validateOnChange?: boolean
    validateOnBlur?: boolean
    onSubmit?: (values: T, formikHelpers: FormikHelpers<T>) => void
    cancelButtonText?: string
    cancelButtonEnabled?: boolean
    confirmButtonEnabled?: boolean
    confirmButtonText?: string
    showResetButton?: boolean
    verticalPadding?: boolean
    className?: string
    children: ReactNode | ReactNode[]
    closeHandler: () => void
    onModalClose?: () => void
    customButtonBar?: ReactNode
}

export default function TebutoModal<T extends FormikValues = FormikValues>({
    isOpen,
    title,
    subTitle,
    initialValues = {} as T,
    onSubmit = () => {},
    validationSchema,
    cancelButtonText = COMPONENT_TEXTS.modals.cancelButtonText,
    confirmButtonText,
    confirmButtonEnabled,
    cancelButtonEnabled = true,
    validateOnBlur = true,
    validateOnChange = true,
    className,
    children,
    closeHandler,
    onModalClose = closeHandler,
    showResetButton = true,
    verticalPadding = true,
    customButtonBar
}: ITebutoModal<T>) {
    return (
        <Transition appear show={isOpen}>
            <Dialog as="div" className="relative z-50" onClose={onModalClose}>
                <TransitionChild enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </TransitionChild>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center text-center">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <DialogPanel
                                className={`${className} flex flex-col w-full h-auto max-h-[90vh] md:rounded-2xl overflow-hidden bg-white text-left align-middle shadow-xl transition-all`}
                            >
                                <XMarkIcon className="h-6 w-6 absolute right-4 top-4 text-gray-500 hover:cursor-pointer" onClick={closeHandler} />
                                <DialogTitle className={'tracking-tight xl:text-3xl lg:text-xl text-lg px-10 py-4 border-b border-gray-200 bg-gray-50'}>
                                    <div>
                                        {subTitle && <SmallText className="mt-2">{subTitle}</SmallText>}
                                        <LargeText className={'text-lg font-semibold text-gray-600'}>{title}</LargeText>
                                    </div>
                                </DialogTitle>

                                <div className={classNames('flex-grow flex flex-col overflow-hidden', verticalPadding ? 'mt-6' : '')}>
                                    <Formik<T>
                                        validateOnChange={validateOnChange}
                                        validateOnBlur={validateOnBlur}
                                        initialValues={initialValues}
                                        onSubmit={onSubmit}
                                        onReset={closeHandler}
                                        validationSchema={validationSchema}
                                        validateOnMount={true}
                                    >
                                        {({ isValid }) => (
                                            <FormContent<T>
                                                confirmButtonText={confirmButtonText}
                                                confirmButtonEnabled={confirmButtonEnabled !== undefined ? confirmButtonEnabled : isValid}
                                                cancelButtonEnabled={cancelButtonEnabled}
                                                cancelButtonText={cancelButtonText}
                                                showResetButton={showResetButton}
                                                verticalPadding={verticalPadding}
                                                closeHandler={closeHandler}
                                                customButtonBar={customButtonBar}
                                            >
                                                {children}
                                            </FormContent>
                                        )}
                                    </Formik>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

function FormContent<T extends FormikValues>({
    children,
    confirmButtonText,
    confirmButtonEnabled,
    cancelButtonEnabled,
    cancelButtonText,
    showResetButton,
    verticalPadding,
    customButtonBar
}: Partial<ITebutoModal<T>>) {
    const { isSubmitting: loading, isValid, dirty } = useFormikContext<T>()
    return (
        <Form className={classNames('flex flex-col flex-grow overflow-hidden', verticalPadding ? 'gap-8' : '')}>
            <div className={classNames('px-10 w-full flex-grow overflow-y-auto', verticalPadding ? 'py-6' : '')}>{children}</div>
            <div className={classNames('border-t border-gray-200 py-4 px-10 bg-gray-50 flex-shrink-0', verticalPadding ? 'mt-4' : '')}>
                {customButtonBar && customButtonBar}
                {!customButtonBar && (
                    <FormButtons
                        saveButtonLabel={confirmButtonText}
                        resetButtonLabel={cancelButtonText}
                        loading={loading}
                        showResetButton={showResetButton}
                        saveButtonEnabled={confirmButtonEnabled === undefined ? dirty && isValid : confirmButtonEnabled}
                        resetButtonEnabled={cancelButtonEnabled}
                    />
                )}
            </div>
        </Form>
    )
}
