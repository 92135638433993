import { useEffect } from 'react'

export default function useBeforeUnload(shouldPreventUnload: boolean, message?: string) {
    useEffect(() => {
        const abortController = new AbortController()

        if (shouldPreventUnload) {
            window.addEventListener(
                'beforeunload',
                ev => {
                    ev.preventDefault()
                    ev.returnValue = message ?? '' // for backwards compatibility
                },
                { capture: true, signal: abortController.signal }
            )
        }

        return () => abortController.abort()
    }, [shouldPreventUnload, message])
}
