'use client'

import { LoadingScreen } from '@tebuto/layout/LoadingScreen'
import { persistor } from '@tebuto/state/store'
import { useState } from 'react'
import { PersistGate } from 'redux-persist/integration/react'

export function TebutoPersistGate({ children }: { children: React.ReactNode }) {
    const [loading, setLoading] = useState(true)

    return (
        <PersistGate
            loading={null}
            persistor={persistor}
            onBeforeLift={() => {
                setTimeout(() => setLoading(false), 1000)
            }}
        >
            <LoadingScreen show={loading} />
            {children}
        </PersistGate>
    )
}
